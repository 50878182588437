import Link from 'next/link';
import { ReactNode } from 'react';
import Spinner from './Spinner';

interface ButtonType {
  disabled?: boolean;
  href?: string;
  loading?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  target?: '_blank';
  children: ReactNode;
  color: 'stratos' | 'torchred' | 'white';
  title?: string;
  type?: 'button' | 'reset' | 'submit';
  size?: 'sm';
}

const Button = ({
  disabled = false,
  href,
  loading,
  onClickHandler,
  target,
  type = 'button',
  title,
  size,
  children,
  color,
}: ButtonType) => {
  const button = (
    // eslint-disable-next-line react/button-has-type
    <button
      disabled={disabled}
      title={title}
      onClick={onClickHandler ? (e) => onClickHandler(e) : null}
      type={type}
      className={`flex flex-nowrap justify-center items-center ${
        size === 'sm' ? 'py-1' : 'py-3'
      } ${
        size === 'sm' ? 'px-6' : 'px-12'
      } border-2 border-${color} text-${color} font-bold ${
        size === 'sm' ? 'text-sm' : 'text-base'
      } uppercase  ${
        color !== 'white' ? 'hover:text-white' : 'hover:text-stratos'
      } ${
        color === 'stratos'
          ? 'hover:bg-stratos'
          : color === 'torchred'
          ? 'hover:bg-torchred'
          : 'hover:bg-white'
      } transition-all ${disabled ? 'opacity-30' : 'opacity-100'}`}
    >
      {children}
      {loading && (
        <span className="ml-2">
          <Spinner />
        </span>
      )}
    </button>
  );

  const link = (
    <Link href={href} passHref>
      <a
        title={title}
        role="button"
        target={target}
        className={`flex flex-nowrap justify-center items-center ${
          size === 'sm' ? 'py-1' : 'py-3'
        } ${
          size === 'sm' ? 'px-6' : 'px-12'
        } border-2 border-${color} text-${color} text-center font-bold ${
          size === 'sm' ? 'text-sm' : 'text-base'
        } uppercase  ${
          color !== 'white' ? 'hover:text-white' : 'hover:text-stratos'
        } ${
          color === 'stratos'
            ? 'hover:bg-stratos'
            : color === 'torchred'
            ? 'hover:bg-torchred'
            : 'hover:bg-white'
        } transition-all ${disabled ? 'opacity-30' : 'opacity-100'}`}
      >
        {children}
      </a>
    </Link>
  );

  return href ? link : button;
};

export default Button;
