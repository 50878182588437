import React from 'react';

interface Props {
  name: string;
  type: 'text' | 'password';
  // eslint-disable-next-line no-unused-vars
  onBlurHandler?: (event: React.FocusEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const FormInput = ({
  name,
  type = 'text',
  onBlurHandler,
  onChangeHandler,
  value,
}: Props) => {
  return (
    <input
      name={name}
      type={type}
      onBlur={onBlurHandler ? (e) => onBlurHandler(e) : null}
      onChange={(e) => onChangeHandler(e)}
      value={value}
      className="appearance-none border border-stratos w-full py-3 px-4"
    />
  );
};

export default FormInput;
