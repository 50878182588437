import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-mineshaft">
      <div className="container mx-auto px-4 py-8">
        <div className="space-y-6 md:flex md:flex-auto md:space-x-12 md:space-y-0">
          <div>
            <h4 className="mb-2 font-extrabold text-torchred uppercase">
              Contact Us
            </h4>
            <div className="flex flex-col space-y-2">
              <a
                href="tel:0894889488"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-sm font-bold hover:text-torchred transition-colors"
              >
                08 9488 9488
              </a>

              <a
                href="mailto:info@marketforce.com.au"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-sm font-bold hover:text-torchred transition-colors"
              >
                info@marketforce.com.au
              </a>
            </div>
          </div>
          <div>
            <h4 className="mb-2 font-extrabold text-torchred uppercase">
              Marketforce Group
            </h4>
            <div className="flex flex-col space-y-2">
              <a
                href="https://marketforce.com.au"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-sm font-bold hover:text-torchred transition-colors"
              >
                Marketforce
              </a>

              <a
                href="https://omdwa.com.au"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-sm font-bold hover:text-torchred transition-colors"
              >
                OMD WA
              </a>

              <a
                href="https://www.marketforce.com.au/services/content"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-sm font-bold hover:text-torchred transition-colors"
              >
                Flare
              </a>
              <a
                href="https://walga-buyers-guide-website.vercel.app"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-sm font-bold hover:text-torchred transition-colors"
              >
                WALGA Buyers Guide
              </a>
            </div>
          </div>
          <p className="flex-1 self-end text-white text-xs md:text-right">
            &copy; Marketforce Pty Ltd {new Date().getFullYear()}.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
