import React from 'react';

interface Props {
  children: React.ReactNode;
}

const ErrorMessage = ({ children }: Props) => {
  return (
    <p className="py-4 px-2 mb-4 border-l-4 border-torchred text-sm text-torchred">
      {children}
    </p>
  );
};

export default ErrorMessage;
