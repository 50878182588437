import React from 'react';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import styles from './SliderContainer.module.css';

import 'swiper/css';
import 'swiper/css/pagination';

import useSWR from 'swr';
import { fetcher } from '../../utils/fetcher';
import Layout from '../../components/Layout';
import ErrorMessage from '../../components/ErrorMessage';
import Link from 'next/link';

SwiperCore.use([Pagination]);

const SliderContainer = () => {
  const { data, error } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/news-items?populate[0]=image`,
    fetcher,
  );
  if (error)
    return (
      <Layout>
        <ErrorMessage>Error fetching data!</ErrorMessage>
      </Layout>
    );
  console.log('data', data);

  return (
    <>
      <div className="px-8 py-8 md:px-20 md:py-0 ">
        <h1 className="text-4xl font-bold text-torchred">Recent Projects</h1>
        <p className="text-2xl text-white pt-1">
          Take a look at some of the work we have produced recently...
        </p>
      </div>
      <div className="container p-8 pt-0 flex items-center">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          centeredSlides={true}
          grabCursor={true}
          pagination={true}
          breakpoints={{
            '640': {
              slidesPerView: 1,
              spaceBetween: 20,
              centeredSlides: false,
            },
            '770': {
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: false,
            },
            '1030': {
              slidesPerView: 3,
              spaceBetween: 30,
              centeredSlides: false,
            },
            '1200': {
              slidesPerView: 4,
              spaceBetween: 30,
              centeredSlides: false,
            },
          }}
          className={`mySwiper ${styles.swiperAdjust}`}
        >
          {!!data?.data?.length &&
            data?.data?.reverse().map((card, i) => (
              <SwiperSlide key={i}>
                <div className="flex flex-col transition-all duration-0 md:duration-150 shadow-lg hover:md:scale-105 hover:md:shadow-xl  hover:md:shadow-[#02022a] rounded-md">
                  <Image
                    src={card?.image?.url || 'https://picsum.photos/300/300'}
                    alt={card?.title}
                    className="object-center object-cover rounded-t-md "
                    width="600"
                    height="600"
                  />
                  <div className="">
                    <Link href={card?.link || '/'}>
                      <a target="_blank">
                        <div className="bg-white p-6 pt-2 rounded-b-md">
                          <p className="text-xs text-torchred">News</p>
                          <h1 className="text-lg text-stratos font-semibold pt-1">
                            {card?.title}
                          </h1>
                        </div>
                      </a>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
};

export default SliderContainer;
