import Head from 'next/head';
import { ReactNode } from 'react';
import Logo from './common/Logo';
import SliderContainer from './SliderContainer';

interface Props {
  children: ReactNode;
}

const SignInLayout = ({ children }: Props) => {
  return (
    <>
      <Head>
        <title>ADSplus</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <div className="md:flex flex-1">
          <div className="flex flex-col md:w-1/2 lg:w-1/3">
            <div className="p-8 md:p-12 xl:p-24">
              <div className="w-10/12 mb-12">
                <Logo />
              </div>
              <div className="mb-8">{children}</div>
            </div>
          </div>
          <div className="flex flex-col justify-center flex-wrap w-full md:w-1/2 lg:w-2/3 bg-stratos overflow-hidden">
            <SliderContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInLayout;
