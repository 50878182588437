import { useState } from 'react';
import { signOut, useSession } from 'next-auth/client';
import Logo from './common/Logo';
import NavItem from './common/NavItem';

const Header = () => {
  const [session] = useSession();
  const [navActive, setNavActive] = useState(false);

  return (
    <header>
      <div className="py-2 bg-gray-100">
        <div className="container flex items-center justify-end space-x-2 mx-auto px-4">
          {session && (
            <>
              <p className="text-mineshaft text-xs">
                Welcome, {session.user.username}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-mineshaft"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <button onClick={() => signOut()}>
                <span className="sr-only">Sign Out</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 hover:text-torchred transition-colors"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </button>
            </>
          )}
        </div>
      </div>
      <div className="relative border-b-2 border-gray-100">
        <div className="container mx-auto px-4 py-8">
          <div className="flex items-center justify-between">
            <Logo />
            {session && (
              <>
                <button
                  className="w-8 h-8 md:hidden"
                  onClick={() => setNavActive(!navActive)}
                >
                  {!navActive && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-full w-full"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16m-7 6h7"
                      />
                    </svg>
                  )}
                  {navActive && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-full w-full"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
                <nav
                  className={`${
                    navActive ? 'absolute' : 'hidden'
                  } left-0 top-full w-full mt-1 flex flex-col md:flex md:flex-row md:justify-end bg-white border-b-4 md:border-0 border-torchred  transition-all duration-300`}
                >
                  <NavItem href="/orders/create">New Order</NavItem>
                  <NavItem href="/orders/current">Current Orders</NavItem>
                  <NavItem href="/orders/archive">Archived Orders</NavItem>
                </nav>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
