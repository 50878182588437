import React from 'react';

interface Props {
  htmlFor: string;
  text: string;
  required?: boolean;
}

const FormLabel = ({ htmlFor, text, required }: Props) => {
  return (
    <label htmlFor={htmlFor} className="mb-3 text-stratos text-sm font-bold">
      {text}
      {required && '*'}
    </label>
  );
};

export default FormLabel;
