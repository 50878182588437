import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';

interface Props {
  children: string;
  href: string;
}

const NavItem = ({ children, href }: Props) => {
  const router = useRouter();

  return (
    <Link href={href}>
      <a
        className={`px-4 py-4 border-b-2 md:border-0 border-gray-100 font-bold transition-colors cursor-pointer hover:text-torchred 
        ${router.pathname == href ? `text-torchred` : `text-mineshaft`}`}
      >
        {children}
      </a>
    </Link>
  );
};

export default NavItem;
