import Link from 'next/link';
import React from 'react';

const Logo = () => (
  <Link href="/">
    <a className="w-36">
      <span className="sr-only">ADSplus</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 210 83"
        width="100%"
        className="w-full"
      >
        <g fill="none" stroke="#212121" strokeWidth="5">
          <path stroke="none" d="M0 0h118v83H0z" />
          <path d="M2.5 2.5h113v78H2.5z" />
        </g>
        <path fill="#fff" d="M16 16h194v56H16z" />
        <text
          transform="translate(22 55)"
          fill="#212121"
          fontSize="40"
          fontFamily="Montserrat-Black, Montserrat"
          fontWeight="800"
        >
          <tspan x="0" y="0">
            ADS
          </tspan>
          <tspan y="0" fill="#ff0032">
            plus
          </tspan>
        </text>
      </svg>
    </a>
  </Link>
);

export default Logo;
